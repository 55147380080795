<template>
    <!-- Primary use of this component is for going to different pages -->
    <!-- But it's emit action could theoretically allow you to utilize it for going to different sections on a single page via anchor -->
    <div class="add-member-menu">
        <div v-for="(page, i) in pages" :key="i">
            <button @click="doAction(page)" :class="getClass(page.name)">
                <div class='add-member-menu-icon'>
                    <img src="@/assets/px/add-company.svg" alt=""/>
                </div>
                <div :class="page.name == $route.name ? 'add-member-menu-label' : 'add-member-menu-label'">{{
                    page.label }}
                </div>
            </button>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        pages: {
            type: Array,
            required: true
        }
    },
    name: 'AddCompanyTab',
    data() {
        return {
           
        };
    },
    methods: {
        doAction(page) {
            this.$emit('action', page);
        },
        getClass(pageName) {
            let classes = 'add-member-menu-button'
         
            if (pageName == this.$route.name) {
                classes += ' add-member-menu-active'
            }

            return classes
        }
    }
};
</script>
