<template>
    <div class="image-container" :class="imgClassContainer" style="">
        <DynamicImage
            :alt="alt"
            :class="imgClass"
            class="img-wrap"
            :fileId="fileId"
            :kind="kind"
            :user="user"
            :client="client"
            :urlToRetrieveFile="imgUrl"
            @getById="getFileId"
            style=""
            :localData="localData"
            :storeKey="storeKey"
            :storeFileId="storeFileID"
            v-if="!reload"
        />
        <div class="overlay" :class="overlayClass" v-if="editProfile">
            <div class="upload" @click="selectFile">
                <span class="material-icons-outlined">file_upload</span> &nbsp;{{ uploadText }}
            </div>
            <div class="zoom" @click="zoom">
                <span class="material-icons-outlined">zoom_out_map</span> &nbsp;View Larger
            </div>
            <div><input id="fileid" type="file" name="upload" ref="upload" @change="uploadFile" hidden /></div>
        </div>
        <NoButtonModal :open="showViewLargerModal" @close="showViewLargerModal = false">
            <div class="modal-attendance-history" style="position: sticky; top: 0px; left: 0px; right: 0px; z-index: 99; background: white; text-align: right;">
                    <button class="bottom-20 pad-0 no-bg" @click="showViewLargerModal = false" type="button"><span class="material-icons">close</span></button>
            </div>
            <DynamicImage
                :alt="alt"
                :class="imgClass"
                class="img-wrap"
                :fileId="fileId"
                :kind="kind"
                :user="user"
                :client="client"
                :urlToRetrieveFile="imgUrl"
                @getById="getFileId"
                style=""
                :localData="localData"
                :storeKey="storeKey"
                :storeFileId="storeFileID"
                v-if="!reload"
            />
        </NoButtonModal>
    </div>
</template>

<script>
    /**
     * When using this component for uploading images, that the props will be used in conjunction with your api request for upload and retrieving files.
     *
     */
    import DynamicImage from '@/components/general/image/Image.vue';
    import NoButtonModal from '@/components/general/modals/NoButtonModal';

    export default {
        name: 'CompanyLogoUpload',
        components: { DynamicImage, NoButtonModal },
        props: {
            placeholder: {
                type: String,
            },
            alt: {
                type: String,
            },
            imgClass: {
                type: String,
            },
            imgClassContainer: {
                type: String,
            },
            overlayClass: {
                type: String,
            },
            uploadText: {
                type: String,
                default: 'upload',
            },
            removeText: {
                type: String,
                default: 'remove',
            },
            kind: {
                type: String,
            },
            user: {
                type: Object,
                default: null,
            },
            client: {
                type: Object,
                default: null,
            },
            urlToUpload: {
                type: String
            },
            imgUrl: {
                type: String,
            },
            notApiPersistent: {
                type: Boolean,
                default: false,
            },
            storeGetterKey: {
                type: String,
                default: null,
            },
            storeUpdateFileKey: {
                type: String,
                default: null,
            },
            storeUpdateFileField: {
                type: String,
                default: null,
            },
            storeUpdateFileIDKey: {
                type: String,
                default: null,
            },
            storeUpdateFileIDField: {
                type: String,
                default: null,
            },
            storeFileID: {
                type: [Number, String],
                default: null
            },
            editProfile: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                fileId: null,
                retrievedId: null,
                localData: null,
                storeKey: this.storeGetterKey,
                reload: false,
                showViewLargerModal: false
            };
        },
        methods: {
            async selectFile() {
                this.$refs.upload.click();
            },
            async uploadFile(event) {
                if (event.target.files.length > 0 && this.notApiPersistent == false) {
                    //Check for file type to be uploaded
                    const file = event.target.files[0];
                    const fileName = file.name;
                    const fileSize = file.size;
                    let fileNameSplit = fileName.split('.');
                    let fileExtension = fileNameSplit[1];
                    if (!['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'].includes(fileExtension)) {
                        this.$toasted.error("Only png, jpg/jpeg files are allowed");
                        this.$refs.upload.value = '';
                        return;
                    }

                    if (fileSize / 1024 / 1024 > 10) {
                        this.$toasted.error("Image size should be less than 10MB");
                        this.$refs.upload.value = '';
                        return;
                    }

                    let data = new FormData();
                    data.append('file', event.target.files[0]);
                    data.append('kind', this.kind);
                    data.append('client', JSON.stringify(this.client));
                    data.append('user', JSON.stringify(this.user));
                    data.append('fileId', this.retrievedId);
                    let response = {};
                    try {
                        if (this.urlToUpload){
                            response = await this.$api.post(this.urlToUpload, data);
                            if(response.status == 500){
                                return;
                            }
                        } else {
                            this.$toasted.error('No url to upload');
                        }
                    }
                    catch(error){
                        return;
                    }
                    if (!this.storeUpdateField && !this.storeGetterKey && !this.storeGetterKey) {
                        this.fileId = response.data.fileId;
                        this.reload = true;
                        //My little clever hack,
                        this.$nextTick(() => {
                            this.reload = false;
                        }, 100)
                        //set all null string values to null
                        if(response.data) {
                            for (let key in response.data) {
                                if (String(response.data[key]).toLocaleLowerCase() == 'null') {
                                    response.data[key] = null;
                                }
                            }
                        }
                        this.$emit('returnApiData', response.data);
                    }
                    if (
                        this.storeGetterKey &&
                        this.storeUpdateFileKey &&
                        this.storeUpdateFileField &&
                        this.storeUpdateFileIDKey &&
                        this.storeUpdateFileIDField
                    ) {
                        let file = await this.getBase64(event.target.files[0]);
                        this.$store.commit(this.storeUpdateFileIDKey, {
                            field: this.storeUpdateFileIDField,
                            value: response.data.fileId,
                        });
                        this.$store.commit(this.storeUpdateFileKey, {
                            field: this.storeUpdateFileField,
                            value: file,
                        });
                        let temp = this.storeKey;
                        this.storeKey = '';
                        this.$nextTick(() => {
                            this.storeKey = temp;
                        }, 100);
                        // setTimeout(() => {this.storeKey = temp}, 500);
                    }
                }
                if (this.notApiPersistent) {
                    this.localData = event.target.files[0];
                    //Returns file back up.
                    this.$emit('returnLocalData', event.target.files[0]);
                }

                // if(this.storeGetterKey && this.storeUpdateKey && this.storeUpdateField && this.storeUpdateFileIDKey && this.storeUpdateFileField){
                //     let file = await this.getBase64(event.target.files[0]);

                //     this.$store.commit(this.storeUpdateFileIDKey, {
                //         field: this.storeUpdateFileIDField,
                //         value: file
                //     })
                //     this.$store.commit(this.storeUpdateFileKey, {
                //         field: this.storeUpdateFileField,
                //         value: file
                //     })
                //     let temp = this.storeKey;
                //     this.storeKey = "";
                //     setTimeout(() => {this.storeKey = temp}, 500);
                // }
                this.$emit('upload');
            },
            getBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            },
            async zoom() {
                this.showViewLargerModal = true;
            },
            async getFileId(id) {
                this.retrievedId = id;
            },

        }
    };
</script>
